.section-lista-periodo {
  display: flex;
  flex-direction: column;
  width: 80%;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
}


.section-lista-periodo .section-lista-periodo-dias {
  border: 1px solid #aaa;
  border-radius: 5px 5px 0 0;
  margin-top: 5px;
  border-bottom: 0;
}

.section-lista-periodo
 .section-lista-periodo-dias
 .lista-periodo-dia {
  display: flex;
  flex-direction: column;

}



.section-lista-periodo
 .section-lista-periodo-dias
 .lista-periodo-dia 
 .lista-periodo-dia-data{
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.075);
    padding: 10px;
    color: #000;
    font-weight: bold
}

.section-lista-periodo
  .section-lista-periodo-dias
  .lista-periodo-dia
  .periodo-dia-agendamentos {
    
}

.section-lista-periodo
  .section-lista-periodo-dias
  .lista-periodo-dia
  .periodo-dia-agendamentos
  .periodo-dia-agendamento {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #aaa;
    padding: 10px 15px;
}


.section-lista-periodo
  .section-lista-periodo-dias
  .lista-periodo-dia
  .periodo-dia-agendamentos
  .periodo-dia-agendamento:hover {
    transform: scale(1.01);
    background-color: var(--primary-default-opacity60);
    color: #fff;
    font-weight: bold;
    transition: .2s;
    border-radius: 5px;
}



.section-lista-periodo
  .section-lista-periodo-dias
  .lista-periodo-dia
  .periodo-dia-agendamentos
  .periodo-dia-agendamento div{
    display: flex;
    flex-direction: row;
    gap:2rem
  }

  .section-lista-periodo
  .section-lista-periodo-dias
  .lista-periodo-dia
  .periodo-dia-agendamentos
  .periodo-dia-agendamento #periodo-dia-agendamento-div-1{
    
    width: 50%;
  }

  .section-lista-periodo
  .section-lista-periodo-dias
  .lista-periodo-dia
  .periodo-dia-agendamentos
  .periodo-dia-agendamento #periodo-dia-agendamento-div-1 div{
    min-width: 80px;
    width: 50%;

  }


  .section-lista-periodo
  .section-lista-periodo-dias
  .lista-periodo-dia
  .periodo-dia-agendamentos
  .periodo-dia-agendamento #periodo-dia-agendamento-div-2{
    
  }

  .section-lista-periodo
  .section-lista-periodo-dias
  .lista-periodo-dia
  .periodo-dia-agendamentos
  .periodo-dia-agendamento #periodo-dia-agendamento-div-2 div span{
    
  }