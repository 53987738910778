.content-lista-guias {
    border: 1px solid #aaa;
    padding: 15px;
    border-radius: 5px;
    margin-top: 25px;
}

.content-lista-guias div {
    display: flex;
    justify-content: space-between;
}

.content-lista-guias div button {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border-radius: 2px;
    border: 0;
    background-color: #3ea662;
    color: #fff;
    gap: 3px;
}

.content-lista-guias div h3 {
    margin: 15px 0 20px 0;
}

.table-listagem-guias {
    border-collapse: collapse;
    font-size: 15px;
    width: 100%;
}

.table-listagem-guias thead {
    background-color: #aaa;
    color: #000;
}

.table-listagem-guias thead tr th {
    font-weight: bold;
}

.table-listagem-guias th,
.table-listagem-guias td {
    border-bottom: 1px solid #aaa;
    padding: 0.2rem 0.6rem;
    font-size: 1.1rem;
}

.table-listagem-guias tbody tr td {}

.table-listagem-guias tbody tr td button {
    border: 0;
    padding: 5px;
    margin: 5px 5px 5px 0;
    flex-direction: row;
}
