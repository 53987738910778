

.content {
  padding: 0 10% 0 10%;
  overflow: auto;
}

.container-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.container-login .login-area{
  display: flex;
  justify-content: center;

}

.container-login .login-area img{
  width:  250px;
  height: auto;
}

.container-login form{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin-top: 25px;}

.container-login form input{
  padding: 5px;
  border-radius: 4px;
  margin: 10px 0;
  border: 1px solid var( --primary-default-opacity60);
  width: 300px;
  max-width: 300px;
}

.container-login form button{
  background-color: var( --primary-default);
  color: var(--text-color-secondary);
  padding: 8px;
  border-radius: 10px;
  border: 0;
  width: 300px;
}