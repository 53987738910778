.title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:  space-between;
  margin: .1em 0;
  padding: 0.5em;
  border-bottom: 2px solid var(--text-color-primary);
}

.title span{
  color: var(--text-color-primary);
  margin-left: 0.8em;
  font-size: 1.1em;
  font-weight: bolder;
  /* font-style: italic; */
}
.view-title{
  display: flex;
  flex-direction: column;

}

.view-title .address{
  display: flex;
  justify-content: flex-end;
  font-style: italic;
  /* color: var(--primary-variant); */
  font-size: .9em;
}

a {
  /* color: var(--primary-variant); */
}