.sidebar{
  width: 200px;
  margin: 0;
  /* padding: 10px; */
  background-color: var(--primary-default);
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar div{
  /* background: url('../../assets/cover.png'); */
  background-color: var(--primary-default);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
}

.sidebar div{
  padding-top: 30px;
}

.sidebar .links {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar .view-logo{
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar div img {
  width: 90px;
  height: 90px;
  display: block;
  margin: auto;
  border-radius: 50%;
  object-fit: cover;
  filter: drop-shadow(2px 3px 6px #121212);
  -webkit-filter: drop-shadow(2px 3px 6px #121212);
}

.sidebar a{
  display: block;
  padding: 16px;
  display: flex;
  text-decoration: none;
  color: rgba(255,255,255, 0.7);
  flex-direction: row;
  align-items: center;
  transition: ease-in-out 0.4s;
}

.div-nome-usuario {
  width: 100%;
  display: flex;
  padding: 16px;

  color: #fff;
  justify-content: start;
  align-items: center;
  gap: 5px
}

.div-nome-usuario button{
  background-color: transparent;
  border: 0;
  color: #fff;
}

.sidebar a svg{
  margin-right: 0.5rem;
}

.sidebar a:hover{
  background-color: rgba(0, 0, 0, .2);
  color: #FFF;
}
.active{
  background-color: rgba(0, 0, 0, .2);
  color: #FFF;
}

.content{
  margin-left: 200px;
  padding: 1px 16px;
}

@media screen and (max-width: 700px){
  .sidebar{
    width: 100%;
    height: auto;
    position: relative;
  }

  div.content{
    margin-left: 0;
  }

  .sidebar a{
    float: left;
  }

  .sidebar div{
    display: none;
  }

  .sidebar a svg{
    display: none;
  }
}


@media screen and (max-width: 400px){
  .sidebar a{
    text-align: center;
    float: none;
  }
  
  .sidebar a svg{
    display: none;
  }
}