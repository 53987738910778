.content-modal-novo-agendamento {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content-modal-novo-agendamento form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.content-modal-novo-agendamento form header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #aaa;
  height: 10%;

  max-height: 40px;
}

.content-modal-novo-agendamento form header button {
  border: 0;
  background-color: transparent;
}

.content-modal-novo-agendamento form main{
    height: 80%;
    max-height: 80%;
  overflow: auto;

    display: flex;
    flex-direction: column;

}

.content-modal-novo-agendamento form main section{
    display: flex;
    gap: 2rem;
}

.content-modal-novo-agendamento form main div{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}
.content-modal-novo-agendamento form main div label{
    font-size: 1.2rem;
}
.content-modal-novo-agendamento form main div input{
    padding: 5px;
    width: 350px;
    border-radius: 4px;
    border: 1px solid #aaa;
}
.content-modal-novo-agendamento form main .table-days-times tbody td,
.content-modal-novo-agendamento form main .table-days-times thead th {
    border-bottom: 2px  dotted #aaa;
    padding: 5px;
}

.content-modal-novo-agendamento form main  .table-days-times tbody{
  overflow: auto;

}


.content-modal-novo-agendamento form main .table-days-times tbody td span{
    border: 1px solid var(--primary-default);
    border-radius: 5px;
    padding: 2px;
    margin: 5px 1px;


}
.content-modal-novo-agendamento form main .table-days-times tbody td span:hover{
    cursor: pointer;
      
  }
.content-modal-novo-agendamento form main .table-days-times tbody td .selected{
    background-color: var(--primary-default);
    color: #fff;
    font-weight: bold;
}


.content-modal-novo-agendamento form footer {

  border-top: 1px solid #aaa;
  height: 10%;
  max-height: 40px;
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.content-modal-novo-agendamento form footer div{
    display: flex;
    flex-direction: column;
}

.content-modal-novo-agendamento form footer button{
    border: 0;
    background-color: var(--primary-default);
    border-radius: 5px;
    padding: 10px 30px;
    color: #fff;
    font-size: 1rem;
}