.section-cadastro-guia {
  display: flex;
  flex-direction: column;
  border: 1px solid #aaa;
  border-radius: 5px;
  gap: 25px;
  padding: 10px;
  justify-content: right;
  align-items: right;
  margin-top: 1rem;
  max-width: 500px;
}

.form-group {
  display: grid;
  align-items: center;
  justify-content: left;
  gap: 5px;
  /* background-color: red; */
}

.form-group label {
  min-width: 200px; /* Ajuste conforme necessário */
}

.form-group input,
.form-group textarea {
  flex: 1;
  padding: 0.5rem;
  width: 400px;
  margin-bottom: 1rem;
  border: 1px solid #aaa;
}

.form-group input:focus,
.form-group textarea:focus {
  border: 0;
  border-left: 5px solid var(--primary-default);
}

.button-submit-cadastro-guia {
  padding: 5px;
  width: 150px;
}
