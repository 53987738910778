.content-lista-profissionais{
    border: 1px solid #aaa;
    padding: 15px;
    border-radius:5px ;
    margin-top:25px;
}

.content-lista-profissionais div {
    display: flex;
    justify-content: space-between;
}

.content-lista-profissionais div button{
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border-radius: 2px;
    border: 0;
    background-color: #3ea662;
    color: #fff;
    gap: 3px
}
.content-lista-profissionais div h3{
    margin: 15px 0 20px 0;
}

.table-listagem-profissionais{
    /* background-color: red; */
    border-collapse: collapse; /* Colapso das bordas */
    font-size: 15px;
    width: 100%;
}

.table-listagem-profissionais thead{
    background-color: #aaa;
    color: #000;
}

.table-listagem-profissionais thead tr th{
    font-weight: bold;





}

.table-listagem-profissionais th, .table-listagem-profissionais td {
    border-bottom: 1px solid #aaa;
    padding: 0.2rem .6rem ;
    font-size: 1.1rem;
}

.table-listagem-profissionais tbody tr td button{
    border: 0;
    padding: 5px;
}