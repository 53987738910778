@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  font: 14px;
  font-family: "Open Sans", sans-serif;
  background-color: var(--backgroud-primary);
      /* font-size: 0.875rem; */
    font-weight: 400;
    line-height: 1.5;
    color: #23282c;
    text-align: left;
}

a {
  text-decoration: none;
  color: var(--primary-variant);
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

:root {
  --primary-default: rgba(32, 168, 216, 1);
  --primary-default-opacity20: rgba(32, 168, 216, .2);
  --primary-default-opacity60: rgba(32, 168, 216, .6);
  --primary-variant: rgb(42, 58, 173);
  --primary-hover: hsl(233, 90.5%, 63.8%);
  --backgroud-primary: rgb(237, 241, 246);
  --background-variant: rgb(255, 255, 255);
  --background-tertiary: rgba(44, 62, 196, 0.05);
  --green: #4dbd74;
  --text-color-primary: rgb(50, 50, 50);
  --text-color-secondary: rgb(255, 255, 255);

  --blue: #20a8d8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f86c6b;
  --orange: #f8cb00;
  --yellow: #ffc107;

  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #73818f;
  --gray-dark: #2f353a;
  --light-blue: #63c2de;
  --primary: #20a8d8;
  --secondary: #c8ced3;
  --success: #4dbd74;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #f86c6b;
  --light: #f0f3f5;
  --dark: #2f353a;
}
html {
  font-size: 14px;
}
html {
  line-height: 1.15;
  text-size-adjust: 100%;
}
