.content{
    overflow: auto;
    width: 100%;
}

.btn-atualizar-agenda{
    background-color: var(--primary-default);
    color: #fff;
    border-radius: 5px;
    border: 0;
    padding: 5px;
    margin: 5px;
}